$(function() {
    //- Obtenemos el formulario.
    var form = $('.form');

    //- Obtenemos el div de mensajes.
    var message = $('.messages');

    // VAriable para guardar los archivos enviados
    let attachment = $('[type=file]').files;
    // Creamos una variable donde guardaremos los datos del formulario
    var form_data;

    //
    // form.addEventListener('submit', send);

    // Esto se ejecuta al escuchar un envío
    $(form).submit(function(e) {
        e.preventDefault();
        form_data = new FormData(this);
        if (typeof attachment !== 'undefined') {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                form_data.append('files[]', file);
            }
        }

        $.ajax({
            type: 'POST',
            url: $('form').attr('action'),
            data: form_data,
            processData: false,
            contentType: false,
            statusCode: {
                200: function(response) {
                    $(message)
                        .fadeIn()
                        .removeClass('alert-danger d-none')
                        .addClass('alert-success');
                    $(message).text(response);
                },
                202: function(data) {
                    $(message)
                        .fadeIn()
                        .removeClass('alert-success d-none')
                        .addClass('alert-danger');
                    $(message).text(data.responseText);
                }
            }
        });
    });
});
