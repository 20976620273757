// Import Bootstrap javascript
import 'bootstrap';
// import 'bootstrap/js/dist/util'
// import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/carousel'
// import 'bootstrap/js/dist/modal'

// import 'jquery'

// Import custom styles
import '../scss/index.scss';

import './sendform.js';

import './isotope.js';

import './offcanvas.js';

jQuery(function($) {
  $(window).on('load', function() {
    let $portfolio_selectors = $('.portfolio-filter >li>a');
    if ($portfolio_selectors != 'undefined') {
      let $portfolio = $('.portfolio-items');
      $portfolio.isotope({
        itemSelector: 'li',
        layoutMode: 'fitRows'
      });
      $portfolio_selectors.on('click', function() {
        $portfolio_selectors.removeClass('active');
        $(this).addClass('active');
        var selector = $(this).attr('data-filter');
        $portfolio.isotope({ filter: selector });
        return false;
      });
    }
  });
});

jQuery(function($) {
  $(window).on('load', function() {
    if (navigator.language.slice(0, 2) !== 'es') {
      $('#modal-idioma').modal('show');
    }
  });
});

// Enabling HMR
if (module.hot) {
  module.hot.accept(console.log('Aceptando nuevos cambios...'));
}
